.pageNotFoundBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  justify-content: center;
}

.pageNotFoundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
}

.pageNotFoundTextIconBed {
  background-color: #394048;
  border-radius: 7%;
  padding: 30px 30px 10px 30px;
}

.pageNotFoundIcon {
  font-size: 6rem;
}

.pageNotFoundText {
  color: #f9b321;
  font-size: 30px;
  font-family: 'SourceSansPro';
  font-weight: 100;
  line-height: 60px;
  text-align: center;
}
