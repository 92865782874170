.certificateMenuBed {
  display: flex;
  justify-content: center;
}
.certificateMenuContainer {
  background-color: #394048;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  margin-bottom: 5px;
}

.certificateMenuIconTextBed {
  display: flex;
}

.certificateMenuClipIcon {
  color: #ffff;
  font-size: 20px;
  margin: 5px 5px;
}

.certificateMenuText {
  color: #ffff;
  font-size: 20px;
  padding-top: 5px;
}

.certificateMenuButtonBed {
  display: flex;
}

.certificateMenuActionButton {
  background-color: #278acd;
  color: #ffff;
  border: 1px solid #ffff;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}
.certificateMenuActionButton:hover {
  background-color: #1e71a8;
}

.certificateMenuActionButtonText {
  padding: 3px 10px 5px;
}

@media only screen and (max-width: 1000px) {
  .certificateMenuClipIcon {
    font-size: 15px;
  }
  .certificateMenuText {
    font-size: 15px;
  }
  .certificateMenuActionButtonText {
    font-size: 14px;
    padding: 1px 7px 3px;
  }
}
