.attachedCertificateButton {
  background-color: #278acd;
  border: 2px solid #ffff;
  width: 230px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 6px;
  margin-bottom: 5px;
}
.attachedCertificateButton:hover {
  background-color: #1e71a8;
}
.attachedCertificateButtonClipIcon {
  color: #ffff;
  font-size: 16px;
  margin-top: 2px;
}
.attachedCertificateButtonText {
  color: #ffff;
  font-size: 16px;
}
.attachedCertificateButtonIcon {
  color: #ffff;
  font-size: 16px;
  margin-top: 2px;
}

@media only screen and (max-width: 500px) {
  .attachedCertificateButtonBed {
    display: flex;
    justify-content: center;
  }
  .attachedCertificateButton {
    width: 95%;
  }
}
