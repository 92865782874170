.mediaModalBed {
  background-color: #141820;
  flex: 25;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 5px;
}

.mediaModalContainer {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mediaModalHeading {
  color: #f9b321;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 100;
  text-align: center;
  margin-bottom: 30px;
}

.mediaModalCloseIconBed {
  display: flex;
  justify-content: flex-end;
  margin-right: 20%;
}
.mediaModalCloseIcon {
  color: #f9b321;
  font-size: 20px;
}
.mediaModalCloseIcon:hover {
  color: #f9b521bd;
}
