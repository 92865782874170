.introBed {
  background-color: #232936;
  display: flex;
  justify-content: center;
}
.introIcon {
  color: #274e8c;
  font-size: 8px;
}

.registerBackArrowBed {
  display: flex;
  justify-content: center;
}

.registerBackArrowContainer {
  width: 400px;
  margin-bottom: 30px;
}

.registerBackArow {
  color: #f9b321;
  font-size: 20px;
}

.registerBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.registerContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.registerLogobed {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.registerHeading {
  color: #f9b321;
  font-family: SourceSansPro;
  font-size: 35px;
  text-align: center;
  margin: 20px 0 30px;
}

.registerButtonBed {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.registerGoogleButton {
  background-color: #ff0000;
  color: #ffff;
  border-radius: 25px;
  width: 380px;
  display: flex;
  justify-content: center;
  padding: 13px 0 13px;
  cursor: pointer;
}
.registerGoogleButton:hover {
  background-color: #da0505;
}
.registerGoogleButton:active {
  background-color: #ff0000;
}

.registerFacebookButton {
  background-color: #274e8c;
  color: #ffff;
  border-radius: 25px;
  width: 380px;
  display: flex;
  justify-content: center;
  padding: 13px 0 13px;
  cursor: pointer;
}
.registerFacebookButton:hover {
  background-color: #204175;
}
.registerFacebookButton:active {
  background-color: #274e8c;
}

.registerEmailButton {
  background-color: #f8f8f8;
  color: rgb(14, 14, 14);
  border-radius: 25px;
  width: 380px;
  display: flex;
  justify-content: center;
  padding: 13px 0 13px;
  cursor: pointer;
}
.registerEmailButton:hover {
  background-color: #dddddd;
}
.registerEmailButton:active {
  background-color: #f8f8f8;
}

.registerButtonIcon {
  margin-right: 20px;
  font-size: 22px;
}

.registerButtonText {
  font-weight: 900;
  font-size: 16px;
}

.registerLinkToLogin {
  color: #8be4ff;
  font-family: SourceSansPro;
  text-align: center;
  font-size: 19px;
  margin-top: 20px;
  cursor: pointer;
}
.registerLinkToLogin:hover {
  color: #7cd3ee;
}
.registerLinkToLogin:active {
  color: #8be4ff;
}

.registerEmailInputsBed {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.registerEmailInputsContainer {
  display: flex;
  flex-direction: column;
}

.registerEmailInput {
  background-color: #ffff;
  border-radius: 5px;
  height: 45px;
  width: 380px;
  text-align: center;
  margin-bottom: 7px;
}

.registerEmailInputError {
  background-color: #fdb6b6;
  border: 1px solid #ff0000;
  border-radius: 5px;
  height: 45px;
  width: 380px;
  text-align: center;
  margin-bottom: 7px;
}

.passwordRulesBed {
  margin-bottom: 7px;
}

.passwordRulesText {
  color: #f9b321;
  text-align: center;
}

.registerCheckIconBed {
  text-align: center;
}

.registerCheckIcon {
  color: rgb(109, 255, 42);
  font-size: 38px;
}

.registerButtonBed {
  display: flex;
  justify-content: center;
}

.registerButton {
  color: #ffff;
  background-color: #278acd;
  width: 300px;
  padding: 10px 0 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.registerButton:hover {
  background-color: #237ebb;
}
.registerButton:active {
  background-color: #278acd;
}

.introIconInput {
  background-color: #ffff;
  font-size: 12px;
  border-radius: 25px;
  height: 20px;
  width: 150px;
  text-align: center;
  padding: 13px 0 13px;
  margin-bottom: 7px;
}

.attachCodeButtonBed {
  display: flex;
  justify-content: center;
}

.attachCodeButton {
  color: #ffff;
  background-color: #278acd;
  font-size: 12px;
  border-radius: 25px;
  padding: 2px 10px 5px 10px;
  cursor: pointer;
}
.attachCodeButton:hover {
  background-color: #2178b3;
}
.attachcodebutton:active {
  background-color: #278acd;
}

.registerErrorText {
  color: #ff0000;
  text-align: center;
  margin: -5px 0 10px;
}
