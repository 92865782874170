.emailVerifiedBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  justify-content: center;
}

.emailVerifiedContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.emailVerifiedContentBed {
  display: flex;
  flex-direction: column;
}

.emailVerifyLogo {
  width: 250px;
  align-self: center;
}

.emailVerifiedText {
  color: #f9b321;
  max-width: 90%;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 100;
  text-align: center;
  padding: 30px;
  line-height: 30px;
}

.emailVerifiedButton {
  background-color: #278acd;
  align-self: center;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  width: 100px;
}
.emailVerifiedButton:hover {
  background-color: #2f8fcf;
}
.emailVerifiedButton:active {
  background-color: #195177;
}
.emailVerifiedButtonText {
  color: #ffff;
  padding: 7px;
}
