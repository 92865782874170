.resetPasswordBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resetPasswordContainer {
  display: flex;
  justify-content: center;
}

.resetPasswordFormLogobed {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.resetPasswordFormHeading {
  color: #f9b321;
  font-family: SourceSansPro;
  font-size: 35px;
  text-align: center;
  margin: 20px 0 30px;
}

.resetPasswordFormButtonBed {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.resetPasswordFormButtonIcon {
  margin-right: 20px;
  font-size: 22px;
}

.resetPasswordFormButtonText {
  font-weight: 900;
  font-size: 16px;
}

.resetPasswordFormInputBed {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.resetPasswordFormInputContainer {
  display: flex;
  flex-direction: column;
}

.resetPasswordFormInput {
  background-color: #ffff;
  border-radius: 5px;
  height: 45px;
  width: 380px;
  text-align: center;
  margin-bottom: 7px;
}

.resetPasswordFormInputError {
  background-color: #fdb6b6;
  border: 1px solid #ff0000;
  border-radius: 5px;
  height: 45px;
  width: 380px;
  text-align: center;
  margin-bottom: 7px;
}

.resetPasswordFormButtonBed {
  display: flex;
  justify-content: center;
}

.resetPasswordFormButton {
  color: #ffff;
  background-color: #278acd;
  width: 300px;
  padding: 10px 0 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.resetPasswordFormButton:hover {
  background-color: #237ebb;
}
.resetPasswordFormButton:active {
  background-color: #278acd;
}

.errorsBed {
  margin-top: 15px;
}

.resetPasswordFormErrorText {
  color: #f9b321;
  font-family: SourceSansPro;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}

.resetPasswordTokenErrorBed {
  background-color: #ffff;
  border: #ff0000 2px solid;
  border-radius: 5px;
  margin: 0 15px 0 15px;
}

.resetPasswordTokenErrorText {
  color: #ff0000;
  font-size: 22px;
  text-align: center;
  padding: 20px;
  line-height: 30px;
}

.resetPasswordSuccessBed {
  background-color: #278acd;
  border-radius: 7px;
  padding: 15px;
}

.resetPasswordSuccessText {
  color: #ffff;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
}
