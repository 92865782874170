.headerButtonsBed {
  background-color: #232936;
  width: 100%;
}
.printButtonBed {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.printDownloadButtonIncon {
  font-size: 20px;
}
.cvCloudAttachmentBed {
  display: flex;
  justify-content: center;
}
.cvCloudAttachmentContainer {
  width: 892px;
}

.cvCloudBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  justify-content: center;
}

.cvCloudContainer {
  width: 892px;
  display: flex;
  padding-bottom: 25px;
}

.cvCloudColumnLeft {
  background-color: #278acd;
  flex: 4;
  padding-bottom: 25px;
}

.cvCloudColumnRight {
  background-color: #ffff;
  flex: 8;
  padding-bottom: 25px;
}

/* Left column */

.cvCloudPhotoBed {
  display: flex;
  justify-content: center;
  padding: 30px 0 30px;
}

.cvCloudPhoto {
  border: 7px solid #ffff;
  width: 80%;
  margin-right: 10px;
  margin-left: 10px;
}

.cvCloudCVTitleBed {
  background-color: #ededed;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cvCloudCVTitleNameText {
  color: #278acd;
  font-family: Oswald;
  line-height: 45px;
  font-size: 50px;
  font-weight: 900;
  padding-left: 5%;
  padding-bottom: 2%;
  line-height: 60px;
}

.cvCloudCVTitleDateText {
  color: #278acd;
  font-family: Oswald;
  line-height: 22px;
  font-size: 20px;
  font-weight: 900;
  padding-left: 5%;
  padding-top: 3%;
}

.cvCloudLeftHeading {
  color: #ffff;
  font-family: Oswald;
  font-weight: 900;
  line-height: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 20px;
  text-transform: uppercase;
}

.cvCloudLeftBitBed {
  display: flex;
}

.cvCloudLeftBitContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 5px;
}

.cvCloudLeftFieldBed {
  display: flex;
  padding-bottom: 4px;
}

.cvCloudLeftFieldIcon {
  color: #ffff;
  padding-right: 7px;
  font-size: 20px;
}

.cvCloudLeftReferenceIcon {
  color: #ffff;
  padding-right: 7px;
  font-size: 20px;
}

.cvCloudLeftFieldText {
  color: #ffff;
  font-size: 16px;
  margin-bottom: 2px;
  word-break: break-word;
}

.cvCloudLeftFieldTextBreakWord {
  color: #ffff;
  font-size: 16px;
  margin-bottom: 2px;
  word-break: break-all;
}

.cvCloudLeftHr {
  margin: 5% 5% 2% 5%;
}

.cvCloudSkillBed {
  margin-bottom: 10px;
}

.cvCloudLanguageMain {
  margin-top: -14px;
}

.cvCloudLanguageBed {
  width: 80%;
}

.cvCloudLanguageHeaderBed {
  color: #ffff;
  display: flex;
  font-size: 16px;
  padding-bottom: 3px;
  margin-top: 10px;
}

.cvCloudLanguageProficiency {
  display: flex;
  justify-content: space-between;
}

.cvCloudLanguageProficiencyBed {
  color: #ffff;
}

.cvCloudLanguageProficiencyText {
  margin-left: 14px;
}

.cvCloudLeftBulletIcon {
  font-size: 8px;
  margin-top: 7px;
  margin-right: 5px;
}

.cvCloudLeftTextBed {
  color: #ffff;
  display: flex;
  font-size: 16px;
  padding-bottom: 3px;
}

.cvCloudLeftReferenceTextBed {
  color: #ffff;
  display: flex;
  font-size: 16px;
  padding-bottom: 3px;
}

.cvCloudProficiencyBed {
  margin-right: 40px;
}

.cvCloudLeftFieldsBed {
  margin-bottom: 10px;
  margin-right: 10%;
}

/* Right column */
.cvCloudRightBitBed {
  padding: 10px 20px 0;
}

.cvCloudRightHeading {
  color: #278acd;
  font-family: Oswald;
  font-weight: 900;
  line-height: 22px;
  padding-top: 12px;
  padding-bottom: 6px;
  font-size: 20px;
  text-transform: uppercase;
}

.cvCloudRightFieldsBed {
  padding: 2%;
  margin-bottom: 10px;
}

.cvCloudRightFieldsTwoBed {
  background-color: #ededed;
  padding: 2%;
  margin-bottom: 10px;
}

.cvCloudRightFieldBed {
  display: flex;
  padding-bottom: 5px;
}

.cvCloudRightFieldIcon {
  margin: 3px 6px 0;
}

.cvCloudRightFieldText {
  color: rgb(0, 0, 0);
  font-size: 14px;
}

.cvCloudRightFieldTitle {
  font-size: 16px;
  font-weight: 900;
}

.cvCloudRightHr {
  margin: 3% 2% 0 2%;
}

.subjectsBed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
}

.subjectText {
  margin-right: 7px;
}

.cvCloudLeftSkillTextBed {
  color: #ffff;
  display: flex;
  font-size: 16px;
  padding-bottom: 3px;
}

@media screen and (max-width: 892px) {
  .cvCloudCVContainer {
    width: 90%;
  }
}

@media screen and (max-width: 780px) {
  .cvCloudCVContainer {
    width: 90%;
  }
  .cvCloudPhoto {
    width: 80%;
    border: 3px solid #ffff;
  }
}

@media only screen and (max-width: 500px) {
  .cvCloudBed {
    padding: 0 5px 0 5px;
  }
  .cvCloudColumnLeft {
    flex: 5;
    margin-top: -2px;
  }

  .cvCloudColumnRight {
    flex: 8;
  }
  .cvCloudRightBitBed {
    padding: 5px 20px 0 10px;
  }
  .cvCloudPhotoBed {
    padding: 10px 0 10px;
  }
  .cvCloudCVTitleBed {
    height: 80px;
    padding: 10px 0 15px;
  }
  .cvCloudCVTitleNameText {
    font-size: 18px;
    margin-bottom: -20px;
  }
  .cvCloudCVTitleDateText {
    font-size: 8px;
  }
  .cvCloudLeftBitContainer {
    padding-left: 10px;
    margin-bottom: 5px;
  }
  .cvCloudLeftHeading {
    line-height: 15px;
    padding-top: 6px;
    font-size: 10px;
    margin-bottom: -10px;
  }
  .cvCloudLeftFieldText {
    color: #ffff;
    font-size: 8px;
    line-height: 10px;
  }
  .cvCloudLeftFieldTextBreakWord {
    color: #ffff;
    font-size: 8px;
    line-height: 10px;
  }
  .cvCloudLeftBulletText {
    word-break: break-all;
    font-size: 10px;
  }
  .cvCloudLeftFieldIcon {
    color: #ffff;
    padding-right: 5px;
    font-size: 12px;
  }
  .cvCloudLanguageProficiency {
    line-height: 14px;
  }
  .cvCloudLanguageProficiencyText {
    font-size: 10px;
    width: 20%;
  }
  .cvCloudLanguageBed {
    width: 100%;
  }
  .cvCloudLanguageHeaderBed {
    padding-top: 2px;
    margin-bottom: -7px;
  }
  .cvCloudLanguageLabel {
    font-size: 10px;
  }
  .cvCloudLeftBulletIcon {
    font-size: 5px;
    margin-top: 8px;
    margin-right: 5px;
  }
  .cvCloudLeftSkillTextBed {
    color: #ffff;
    display: flex;
    font-size: 16px;
    margin: 5px 0 5px 0;
  }
  .cvCloudLeftBulletSkillText {
    font-size: 10px;
    line-height: 12px;
    display: flex;
    word-break: break-word;
  }
  .cvCloudProficiencyBed {
    margin: -11px 0 7px 0;
  }
  .cvCloudLeftReferenceIcon {
    color: #ffff;
    padding-right: 7px;
    font-size: 14px;
    margin-top: 3px;
  }
  .cvCloudRightHeading {
    line-height: 20px;
    padding-top: 4px;
    margin-bottom: -3px;
    font-size: 11px;
  }
  .cvCloudRightFieldText {
    font-size: 8px;
    line-height: 12px;
  }
  .cvCloudRightFieldIcon {
    font-size: 8px;
  }
  .cvCloudSkillBed {
    margin-bottom: -8px;
  }
  .cvCloudLeftReferenceTextBed {
    color: #ffff;
    display: flex;
    margin-bottom: -5px;
  }
  .cvCloudRightFieldTitle {
    font-size: 9px;
    margin-bottom: -5px;
  }

  .subjectText {
    margin-right: 7px;
    font-size: 8px;
    line-height: 12px;
  }
}

@media print {
  .headerButtonsBed {
    display: none;
  }
  .cvCloudRightBitBed,
  .cvCloudLeftBitBed {
    page-break-inside: avoid;
  }
}

@-moz-document url-prefix() {
  @media print {
    .cvCloudBed {
      height: auto;
      display: block;
      overflow: visible !important;
    }

    .cvCloudContainer {
      width: 210mm;
      height: auto;
    }

    .cvCloudColumnLeft {
      width: 276px;
      float: left;
    }

    .cvCloudColumnRight {
      width: 517px;
      float: right;
    }
  }
}
