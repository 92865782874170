.burgerMenuModalViewCvBed {
  background-color: #394048;
  width: 250px;
  border-radius: 7px;
  padding-bottom: 10px;
}

.burgerMenuModalLink {
  color: #ffff;
  background-color: #278acd;
  border: 1px solid #ffff;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  margin: 0 15px 5px 15px;
  cursor: pointer;
}
.burgerMenuModalLink:hover {
  color: #ffff;
  background-color: #1f73ac;
}

.burgerMenuModalLinkText {
  color: #ffff;
}
.burgerMenuModalLinkText:hover {
  color: #ffff;
}

.burgerMenuModalCloseButtonBed {
  display: flex;
  justify-content: flex-end;
  height: 40px;
}

.burgerMenuModalCloseButton {
  color: #f9b321;
  font-size: 20px;
  margin: 10px 10px;
  cursor: pointer;
}
