.menuModalBed {
  background-color: #141820;
  flex: 25;
  display: flex;
  justify-content: center;
  margin-top: -120px;
}

.menuModalContainer {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menuModalHeading {
  color: #f9b321;
  text-transform: capitalize;
  font-size: 3rem;
  font-weight: 100;
  text-align: center;
  margin: 10px 0 40px 0;
}

.menuModalCloseIconBed {
  display: flex;
  justify-content: flex-end;
}
.menuModalCloseIcon {
  color: #f9b321;
  font-size: 20px;
}
.menuModalCloseIcon:hover {
  color: #f9b521bd;
}

@media only screen and (max-width: 1000px) {
  .menuModalHeading {
    font-size: 2rem;
    margin: 5px 0 20px;
  }
  .menuModalContainer {
    width: 50%;
  }
}

@media only screen and (max-width: 740px) {
  .menuModalContainer {
    width: 70%;
  }
}
