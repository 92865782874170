.managementViewPinNavButtonBed {
  background-color: #232936;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}

.managementViewPinNavButton {
  background-color: #278acd;
  color: #ffffff;
  border-radius: 7px;
  height: 40px;
}

.managementViewPinInputBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  justify-content: center;
}

.managementViewPinInput {
  height: 40px;
  text-align: center;
  border-radius: 25px;
}

.managementViewPinInputButton {
  background-color: #278acd;
  color: #ffffff;
  border-radius: 25px;
  height: 40px;
  margin-left: 10px;
}

.managementViewPinInputContainer {
  align-self: center;
}
