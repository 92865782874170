.firstImpressionBed {
  background-color: #232936;
  display: flex;
  justify-content: flex-start;
}
.firstImpressionContainer {
  background-color: #278acd;
  border: 2px solid #ffff;
  width: 230px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 6px;
  margin-bottom: 5px;
}
.firstImpressionContainer:hover {
  background-color: #1e71a8;
}
.fisrtImpressionClipIcon {
  color: #ffff;
  font-size: 16px;
  margin-top: 2px;
}
.firstUImpressionText {
  color: #ffff;
  font-size: 16px;
}
.firstImpressionPlayIcon {
  color: #ffff;
  font-size: 16px;
  margin-top: 2px;
}

@media only screen and (max-width: 500px) {
  .firstImpressionBed {
    justify-content: center;
  }
  .firstImpressionContainer {
    width: 95%;
  }
}
