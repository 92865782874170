.landingBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landingContainer {
  display: flex;
  justify-content: center;
}

.landingLogobed {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.landingSlogan {
  color: #cfcfcf;
  font-family: Courier;
  font-size: 20px;
  max-width: 390px;
  line-height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.landingMessageBed {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 390px;
}

.landingMessageOne {
  color: #232936;
  background-color: #f9b321;
  font-size: 20px;
  border-radius: 7px;
  padding: 7px;
  margin-top: 10px;
  text-align: center;
  line-height: 33px;
}

.landingMessageTwo {
  color: #ffff;
  background-color: #278acd;
  font-size: 20px;
  border-radius: 7px;
  padding: 7px;
  margin-top: 10px;
  text-align: center;
  line-height: 25px;
}

.landingAppStoresBed {
  display: flex;
  justify-content: center;
}

.linkToWebAppBed {
  display: flex;
  justify-content: center;
}
.linkToWebAppContainer {
  background-color: #278acd;
  width: 120px;
  height: 38px;
  border-radius: 5px;
  margin: 10px 0 10px;
  align-self: center;
}
.linkToWebAppContainer:hover {
  background-color: #156eaa;
}
.linkToWebApp {
  color: #ffff;
}
.linkToWebApp:hover {
  color: #ffff;
}

.landingShovel {
  margin-top: 10px;
}

.landingAppgalleryLogo {
  margin: 20px 0 0 20px;
  width: 46px;
  height: 46px;
}

.landingAppStoreLogo {
  color: #ffff;
  font-size: 60px;
  margin: 10px;
}

@media only screen and (max-width: 1000px) {
  .landingLogobed {
    padding: 0 10% 10px 10%;
  }
  .landingLogo {
    width: 100%;
    justify-content: center;
  }
  .landingMessageBed {
    padding: 0 10% 0 10%;
  }
  .landingSlogan,
  .landingMessageTwo,
  .landingMessageOne {
    font-size: 15px;
  }
  .landingSlogan {
    font-size: 15px;
    padding: 0 10% 0 10%;
  }
  .landingShovel {
    width: 30%;
  }
}
