.headerBed {
  background-color: #232936;
  display: flex;
  justify-content: space-between;
}

.headerLogoBed {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerLogo {
  margin-left: 50px;
  width: 160px;
  cursor: pointer;
}

.headerMenuButton {
  color: #ffff;
  font-size: 20px;
  margin: 20px 50px;
  cursor: pointer;
}
.headerMenuButton:hover {
  color: rgb(236, 236, 236);
}


@media only screen and (max-width: 320px) {
  .headerLogo {
  display: none;
  }
  .headerBed {
    justify-content: center;
  }
}

@media print {
  .headerBed {
    display: none;
  }
}
