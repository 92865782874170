.termsBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.termsContainer {
  width: 80%;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: 25px 0 25px;
}

.termsHeading {
  color: #ffff;
  font-size: 1.2rem;
  align-self: center;
  margin-bottom: 10px;
}

.termsText {
  color: #ffff;
  line-height: 22px;
  text-align: center;
}

.privacyPolicyLink {
  padding-left: 10px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.privacyPolicyLinkText {
  color: #48b1f7;
  width: 150px;
  text-align: center;
  padding: 5px;
  margin: 5;
}

.exitingIntructionHeading {
  color: #ffff;
  font-size: 1.4rem;
  align-self: center;
  margin-bottom: 10px;
}

.exitingIntructionSubHeading {
  color: #ffff;
  font-size: 1.3rem;
  align-self: center;
  margin-bottom: 10px;
}

.exitingIntructionWarningText {
  color: #ff0000;
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.exitingIntruction {
  color: #ffff;
  line-height: 22px;
  text-align: center;
  font-size: 1.1rem;
  margin-top: 10px;
}

.exitingIntructionTextStepText {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.exitingIntructionTextStepLabel {
  font-weight: 900;
  margin-right: 15px;
}

.exitingIntructionTextStepImage {
  width: 160px;
}
