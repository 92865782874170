.proficiencyWhite {
  color: #ffff;
  font-size: 15px;
  margin-right: 2px;
}

@media only screen and (max-width: 500px) {
  .proficiencyWhite {
    font-size: 10px;
    margin-right: 1px;
  }
}
