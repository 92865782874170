.loginBackArrowBed {
  display: flex;
  justify-content: center;
}

.loginBackArrowContainer {
  width: 400px;
  margin-bottom: 30px;
}

.loginBackArow {
  color: #f9b321;
  font-size: 20px;
}

.loginBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 9px;
}

.loginContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.loginLogobed {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.loginHeading {
  color: #f9b321;
  font-family: SourceSansPro;
  font-size: 35px;
  text-align: center;
  margin: 20px 0 30px;
}

.loginButtonBed {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.loginGoogleButton {
  background-color: #ff0000;
  color: #ffff;
  border-radius: 25px;
  width: 380px;
  display: flex;
  justify-content: center;
  padding: 13px 0 13px;
  cursor: pointer;
}
.loginGoogleButton:hover {
  background-color: #da0505;
}
.loginGoogleButton:active {
  background-color: #ff0000;
}

.loginFacebookButton {
  background-color: #274e8c;
  color: #ffff;
  border-radius: 25px;
  width: 380px;
  display: flex;
  justify-content: center;
  padding: 13px 0 13px;
  cursor: pointer;
}
.loginFacebookButton:hover {
  background-color: #204175;
}
.loginFacebookButton:active {
  background-color: #274e8c;
}

.loginEmailButton {
  background-color: #f8f8f8;
  color: rgb(14, 14, 14);
  border-radius: 25px;
  width: 380px;
  display: flex;
  justify-content: center;
  padding: 13px 0 13px;
  cursor: pointer;
}
.loginEmailButton:hover {
  background-color: #dddddd;
}
.loginEmailButton:active {
  background-color: #f8f8f8;
}

.loginButtonIcon {
  margin-right: 20px;
  font-size: 22px;
}

.loginButtonText {
  font-weight: 900;
  font-size: 16px;
}

.loginLink {
  color: #8be4ff;
  font-family: SourceSansPro;
  text-align: center;
  font-size: 19px;
  margin-top: 20px;
  cursor: pointer;
}
.loginLink:hover {
  color: #7cd3ee;
}
.loginLink:active {
  color: #8be4ff;
}

.loginEmailInputsBed {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.loginEmailInputsContainer {
  display: flex;
  flex-direction: column;
}

.loginEmailInput {
  background-color: #ffff;
  border-radius: 5px;
  height: 45px;
  width: 380px;
  text-align: center;
  margin-bottom: 7px;
}

.loginEmailInputError {
  background-color: #fdb6b6;
  border: 1px solid #ff0000;
  border-radius: 5px;
  height: 45px;
  width: 380px;
  text-align: center;
  margin-bottom: 7px;
}

.loginButtonBed {
  display: flex;
  justify-content: center;
}

.loginButton {
  color: #ffff;
  background-color: #278acd;
  width: 300px;
  padding: 10px 0 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.loginButton:hover {
  background-color: #237ebb;
}
.loginButton:active {
  background-color: #278acd;
}

.loginErrorText {
  color: #ff0000;
  text-align: center;
  margin: -5px 0 10px;
}
