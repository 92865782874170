.colorPalet {
  color: #278acd;
  color: #f9b321;
  color: #394048;
  color: #232936;
  color: #ff0000;
  color: #ededed;
}

.appBed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@font-face {
  font-family: 'Oswald';
  font-weight: 900;
  src: local('Oswald'),
    url(../assets/fonts/oswald/Oswald-Bold.woff2) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-weight: 100;
  src: local('Oswald'),
    url(../assets/fonts/oswald/Oswald-ExtraLight.woff2) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-weight: 200;
  src: local('Oswald'),
    url(../assets/fonts/oswald/Oswald-Light.woff2) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-weight: 400;
  src: local('Oswald'),
    url(../assets/fonts/oswald/Oswald-Medium.woff2) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'),
    url(../assets/fonts/oswald/Oswald-Regular.woff2) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-weight: 800;
  src: local('Oswald'),
    url(../assets/fonts/oswald/Oswald-SemiBold.woff2) format('truetype');
}

@font-face {
  font-family: 'Courier';
  font-weight: 400;
  src: local('Courier'),
    url(../assets/fonts/courier/CourierPrime-Regular.woff2) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro';
  font-weight: 100;
  src: local('SourceSansPro'),
    url(../assets/fonts/sourceSansPro/SourceSansPro-ExtraLight.woff2)
      format('truetype');
}
