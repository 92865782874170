.loaderFullScreenBed {
  background-color: #232936;
  flex: 25;
  display: flex;
  justify-content: center;
}

.loaderFullScreenContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.loaderFullScreenText {
  color: #ffff;
  font-size: 10px;
  padding-top: 10px;
}
