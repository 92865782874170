.dimmer {
  background-color: rgba(0, 0, 0, 0.295) !important;
}

.notificationModalViewCvBed {
  background-color: #394048;
  width: 70%;
  border-radius: 7px;
}

.notificationModalIcon {
  color: #f9b321;
  font-size: 30px;
  margin-top: 20px;
}

.notificationModalText {
  color: #f9b321;
  font-family: 'SourceSansPro';
  font-size: 20px;
  text-align: center;
  padding: 10px;
  line-height: 30px;
  font-weight: 100;
}

.notificationModalCloseBtnBed {
  color: #f9b321;
  display: flex;
  font-size: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.notificationModalCloseBtnText {
  cursor: pointer;
}

.notificationModalCloseBtnIcon {
  margin: 2px 0 0 2px;
  cursor: pointer;
}
