.videoBed {
  display: flex;
  justify-content: center;
}

.videoContainer {
  border: 10px solid #278acd;
  border-radius: 5px;
  width: 300px;
  display: block;
  margin: 0 auto;
}

.firstImpressionViewName {
  color: #f9b321;
  font-size: 25px;
  align-self: center;
  padding-bottom: 10px;
}

.firstImpressionViewDateOfBirth {
  color: #f9b321;
  font-size: 14px;
  align-self: center;
  padding-bottom: 10px;
}
